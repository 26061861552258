import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

const BookNow = () => {
  return (
    <Layout>
      <SEO title={"Our boat"} />
      <div>Book now</div>
    </Layout>
  );
};

export default BookNow;
